<template>
    <div>
        <Topbar></Topbar>
        <div class="pc-title">首页>商城中心>我的订单>申请售后</div>
         <div class="serve-content">
               <div class="buzhou">
                <div class="buzhou-item" v-for="(item,index) in buzhoulist" :key='index'>
                    <div class="buzhou-item-left"> 
                        <div class="buzhou-item-img">
                            <img :src="item.img" alt="">
                        </div>
                        <div class="buzhou-item-font">
                            {{item.text}}
                        </div>
                    </div>
                    <div :class="[index!=buzhoulist.length-2 ? ' line' : ' line2']" v-if="index!=buzhoulist.length-1"></div>
                </div>
            </div>
            <!-- 内容 -->
             <div class="serve-body">
                <div class="body-left">
                    <div class="left-top">
                        申请售后商品
                    </div>


                    <van-checkbox-group v-model="result" ref="checkboxGroup">
                         <van-checkbox
                            :name="index"
                            class="order_info"
                            v-for="(item, index) in detailList"
                            :key="index"
                            @click="changeCheckbox"
                        >
                     <div class="goods-item">
                        <div class="content-left-img">
                            <img :src="item.SpecImg" alt="">
                        </div>
                        <div class="content-left-font">
                            <div class="font1">{{item.GoodsName}}</div> 
                            <div class="font2">{{item.SpecName}}</div>
                            <div class="font2">￥{{item.TotalMoney}}</div>
                        </div>
                    </div>
                         </van-checkbox>
                    </van-checkbox-group>









                </div>
                <div class="body-right">
                    <div class="right-top">请按要求填写售后申请，以便快速通过审核</div>
                    <!-- body -->
                    <div class="added">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
                            <el-form-item label="退款原因" prop="region" >
                                <el-select v-model="refundReason" placeholder="请选择申请售后原因" class="region">
                                    <div v-for="(item,index) in reasonColumns" :key="index">
                                         <el-option :label="item" :value="item" :key="index"></el-option>
                                    </div>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="退款金额">
                                 <el-input style="width: 250px" :value="total"></el-input>
                            </el-form-item>
                            <el-form-item label="退款说明">
                                <el-input type="textarea" v-model="form.desc" placeholder="退款说明"></el-input>
                            </el-form-item>
                            <!-- <div class="fileup">
                                <div class="file-font">上传凭证</div>
                                <div class="file-img">
                                    上传图片
                                </div>
                                <div class="file-font2">最多上传8张，每张大小不超5M；支持GIF,JPG,PNG,BMP格式</div>
                            </div> -->

                            <div class="fileup">
                                <div class="file-font">上传凭证</div>
                                <van-uploader v-model="fileList" :after-read="afterRead" multiple upload-text="添加图片" />
                            </div>
                           <div class="tijiao" @click="postAddShopOrdeRefund">提交</div>
                        </el-form>
                    </div>
                </div>
             </div>
         </div>
        
    </div>
</template>
<script>
import { queryShopOrderDetail, applyRefund } from "@/api/shop";
import { imagesUploadPic } from "@/api/api";
import { Toast,Picker  } from "vant";
import Topbar from '../../PCshop/components/topbar.vue'
export default {
     components:{
        Topbar
    },
    data(){
        return{
             total: 0,
             totalPoint:0,
             reasonColumns: ['不喜欢/不想要', '空包裹','快递/物流一直未送到','快递/物流无跟踪记录',
             '货物破损已拒收','退运费','规格与商品描述不符','做工粗糙/有瑕疵','质量问题','少件/漏发',
             '卖家发错货'],
            form: {
               desc:''
            },
            ruleForm: {
                name: '',
                region: '',
            },
            refundReason:'',
             rules: {
                name: [
                    { required: true, message: '退款金额', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                name2: [
                    { required: true, message: '退款说明', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 0 到 200 个字符', trigger: 'blur' }
                ],
            },
            buzhoulist:[
                {text:'1.申请退款',img:require('../../../assets/images/buzhou1.png')},
                {text:'2.商家审核',img:require('../../../assets/images/buzhou2.png')},
                {text:'3.完成退款',img:require('../../../assets/images/buzhou3.png')},
            ],
           
                    detail:[
                        {
                            left:'订单编号：',
                            right:'236928563299666666666873'
                        },
                        {
                             left:'成交时间：',
                            right:'2021-06-19 01:20'
                        },
                          {
                             left:'商品售价：',
                            right:'￥998 x1'
                        },
                          {
                             left:'运费',
                            right:'￥10'
                        },
                          {
                             left:'优 惠：',
                            right:'￥0'
                        }
                    ],
            detailList:[],
            total:0,
            imgList:[],
            fileList:[],
            refundType:'',//退款类型
            result:[]
        }
    },
    created(){
         this.orderId = this.$route.query.orderId;
         this.detailList=this.$route.query.detaillist;
         this.refundType=this.$route.query.index
         this.TotalMoney=this.detailList[0].TotalMoney
    },
    methods:{
    //提交退款信息
    async postAddShopOrdeRefund() {
      let goodsData = [];
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.detailList[this.result[i]];
        // this.total += obj.Price * obj.GoodsCount * 100;
        console.log(obj,8888)
        let data = {};
        data.GoodsId = obj.GoodsId;
        data.GoodsName = obj.GoodsName;
        data.SpecId = obj.SpecId;
        data.SpecName = obj.SpecName;
        data.ImgUrl = obj.SpecImg;
        data.RefundGoodsCount = obj.RefundCount;
        data.BuyCount = obj.GoodsCount;
        data.OrderId = obj.ShopOrderId;
        data.OrderDetailId = obj.Id;
        // data.PayMoney = obj.TotalMoney;
        // data.PayPoints = obj.Points;
        data.PayMoney = (obj.TotalMoney/obj.GoodsCount) * obj.RefundCount;
        data.PayPoints =(obj.Points/obj.GoodsCount) * obj.RefundCount;
        data.GivePoint =  (obj.GivePoint/obj.GoodsCount) * obj.RefundCount;
        data.ApplyRefundMoney = (obj.TotalMoney/obj.GoodsCount) * obj.RefundCount;
        data.ApplyPoint = (obj.Points/obj.GoodsCount) * obj.RefundCount;
        data.UserId = obj.UserId;
        data.UserName = obj.UserName;
        data.RefundType = this.refundType == 1?0:this.refundType == 0?1:2;
        data.RefundRemark = this.form.desc;
        data.RefundPicture = this.imgList.join(',');
        data.RefundReason = this.refundReason;
        data.RefundGoodsStatus = this.refundType == 2?0:1;
        goodsData.push(data);
      }
      console.log(goodsData);
      if(goodsData.length == 0)
      {
        Toast({
          message:"请选择要退款的商品",
        })
        return false;
      }
      const res = await applyRefund(goodsData);
      if(res.success == true)
      {
          Toast({
              message:res.msg,
            })
            this.$router.push({
            path: "/PCshop/my/shopmy",
        });
      }
      else  
      {
        Toast.fail({
          message:res.msg,
        })
      }
    },

        changeCheckbox() {
            this.total = 0;
            this.totalPoint = 0;
            for (var i = 0; i < this.result.length; i++){
                let obj = this.detailList[this.result[i]];
                this.total += (obj.TotalMoney/obj.GoodsCount) * obj.RefundCount;
                this.totalPoint += (obj.Points/obj.GoodsCount) * obj.RefundCount;
            }
            },

          afterRead(file){
            // 此时可以自行将文件上传至服务器
            console.log(file.file);
            this.postImagesUploadPic(file.file)
        },
            // 上传文件
        async postImagesUploadPic(file){
            const formData = new FormData();
            formData.append("file", file);
            const res = await imagesUploadPic(formData);
            if (res.success == true) {
            let url = this.GLOBAL.hostUrl + res.response;
            this.imgList.push(url);
            console.log(this.imgList);
            } else {
                this.$message.error(res.msg);
            }
       },
    }
}
</script>
<style lang="scss" scoped>
    .pc-title{
        width: 1100px;
        height: 55px;
        margin: 0 auto;
        line-height: 55px;
        color: #666666;
        font-size: 12px;
    }
    .serve-content{
        width: 1100px;
        background:#FFFFFF;
        border: 1px solid #EEEEEE;
        margin: 0 auto 49px;
        .serve-body{
        width: 95%;
        margin: 30px auto 34px;
        height: 539px;
        border: 1px solid red;
    }
    }
    .buzhou{
        .buzhou-item-left{
           width: 70px;
        }
        margin-top: 30px;
        display: flex;
        padding-left: 25px;
        .buzhou-item{
            text-align: center;
            display: flex;
            .buzhou-item-font{
                margin-top: 4px;
            }
        }
        .line{
            width: 417px;
            height: 4px;
            background: #F95E2E;
            margin-top: 20px;
        }
        .line2{
            width: 417px;
            height: 4px;
            background: #EEEEEE;
            margin-top: 20px;
        }
    }
    .serve-body{
        display: flex;
        .body-left{
            width: 360px;
            height: 539px;
        }
        .body-left{
           border: 1px solid #ECEBEB;
           
            .left-top{
                width: 100%;
                background:#F7F7F7;
                border: 1px solid #E9E9E9;
                height: 40px;
                padding-top: 11px;
                padding-left: 16px;
                font-size: 14px;
                font-weight: bold;

            }

        .goods-item{
            display:flex;
            padding-left: 14px;
            padding-top: 21px;
            padding-bottom:20px ;
           .content-left-img{
                width:82px;
                height:82px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .content-left-font{
                margin-left: 14px;
                .font1{
                    font-size: 14px;
                    width: 182px;
                    height: 39px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .font2{
                    width: 182px;
                    font-size: 12px;
                    color: #999999;
                    margin-top: 5px;
                }
            }
            .content-price{
                margin-top: 13px;
                color: #F40707;
                font-weight: 800;
                font-size: 18px;
            }
           }
            .bottom{
                 width: 100%;
                 padding-left: 14px;
                .bottom-item{
                    margin-top: 14px;
                    .bottom-left{
                       color: #999999;
                    }
                }
            }
        }
        .body-right{
             flex: 1;
             padding-right: 45px;
             padding-left: 40px;
             .right-top{
                 font-size: 16px;
                 color: #333333;
                 font-weight: bold;
                 border-bottom:1px solid #EAEAEA ;
                 margin-top: 30px;
                 padding-bottom: 21px;
             }
        }
    }
                .added{
                    margin-top: 20px;
                   .title{
                       margin-top: 30px;
                   }
                   .el-form{
                       .region{
                           width: 260px;
                       }
                       .detail-address{
                           .el-textarea__inner{
                               width: 460px;
                               height: 86px;
                           }
                       }
                   }
                   .fileup{
                       display: flex;
                       .file-font{
                           margin-top: 8px;
                       }
                       .file-img{
                            margin-left: 44px;
                            width: 120px;
                            height: 36px;
                            background: #FFF1EC;
                            border: 1px solid #F95E2E;
                            background-image:url('../../../assets/images/servefile.png');
                            background-repeat: no-repeat;
                            background-position:22% 56%;
                            color: #F95E2E;
                            font-size: 14px;
                            padding-top: 8px;
                            padding-left: 50px;
                       }
                       .file-font2{
                           font-size: 12px;
                           color: #666666;
                           margin-left:20px;
                           margin-top: 10px;
                       }
                   }
                   .tijiao{
                       width: 120px;
                       height: 38px;
                       background:#FA6639;
                       color: #FFFFFF;
                       text-align: center;
                       line-height: 38px;
                       font-size: 16px;
                       margin-top: 26px;
                       margin-left: 99px;
                   }
               }
               /deep/.el-form-item__label{
                   margin-left: -30px !important;
               }
               .row{
                   margin-top: 15px;
                   margin-left: 3px;
               }
               .file-font{
                   margin-right: 72px;
               }
               /deep/.van-checkbox-group{
                   padding-left: 20px;
               }
</style>